<template>
  <div class="container">
    <div class="title">我的API</div>
    <div
      class="content"
      v-loading="loading"
    >
      <div
        :class="['goods_item',item.status?'':'off_goods_item']"
        v-for="item in myGoodsList"
        :key="item.goods_id"
      >
        <div class="left_box">
          <div
            class="goods_img"
            :style="item.pic?'':'background-color: #ecf1fd;'"
          >
            <img
              :src="item.pic"
              alt=""
              v-if="item.pic"
            >
            <span v-else>暂无图片</span>
          </div>
        </div>
        <div class="right_box">
          <div class="right_top">
            <p style="font-size:18px;font-weight:700;">{{item.name}}</p>
            <p>商品编号:{{item.goods_id}}</p>
            <div v-if="item.status">
              <p
                v-if="$store.state.userInfo.detail.auth_status == 0 || $store.state.userInfo.detail.auth_status == 3">
                请求Key：您还未完成实名认证，暂时无法使用该接口<span class="btn">去认证 <i
                    class="el-icon-arrow-right"
                  ></i></span></p>
              <p v-if="$store.state.userInfo.detail.auth_status == 1">
                请求Key:您当前认证信息正在审核，请认证通过后再使用</p>
              <p v-if="$store.state.userInfo.detail.auth_status == 2">
                请求Key:<span class="btn">去查看 <i
                    class="el-icon-arrow-right"></i></span></p>
            </div>
          </div>
          <div class="right_bottom">
            <span @click="goMyGoodsInfo(item)"><i
                class="el-icon-document"
                style="margin-right:5px;"
              ></i>查看详情</span>
          </div>
        </div>
      </div>

      <el-empty
        v-if="myGoodsList.length == 0"
        style="width:100%"
        description="暂无数据"
      ></el-empty>
    </div>
    <div class="pagination-box">
      <el-pagination
        background
        layout=" sizes, prev, pager, next, jumper"
        :page-count="pageTotal"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="
            (val) => {
              handleSizeChange(getMyGoodsList, val);
            }
          "
        @current-change="
            (val) => {
              handleCurrentChange(getMyGoodsList, val);
            }
          "
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getMyGoodsListApi } from '../../../api/goods'
import { paginationMixins } from '../../../mixins/paginationMixins'
export default {
  // 组件名称
  name: '',
  mixins: [paginationMixins],
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      myGoodsList: [],
      loading: false,
      pageSize: 9, // 每页条数
      // pageNum: 1, // 当前页码
      // pageTotal: 0, // 页数总数
      pageSizes: [9, 18, 30, 60] // 页码数组
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.getMyGoodsList()
  },
  mounted () { },
  // 组件方法
  methods: {
    async getMyGoodsList () {
      this.loading = true
      const res = await getMyGoodsListApi({
        page: this.pageNum,
        limit: this.pageSize
      })
      this.loading = false
      console.log(res)
      if (res && res.code === 0) {
        this.myGoodsList = res.data.data
        this.pageTotal = res.data.total_page
      }
    },
    goMyGoodsInfo (val) {
      // console.log(val)
      this.$router.push({
        name: 'MyGoodsInfo',
        params: val
      })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 85px);
    width: 100%;
    padding: 20px 0 0;
    box-sizing: border-box;
    overflow-y: auto;
    // justify-content: space-around;
    .goods_item {
      display: flex;
      width: 450px;
      height: 220px;
      // background-color: #edf1fd;
      margin-left: 20px;
      margin-bottom: 20px;
      border: 1px solid #d3d1d1;
      border-radius: 10px;
      .left_box {
        width: 110px;
        .goods_img {
          width: 70px;
          height: 70px;

          margin: 10px auto;
          font-size: 12px;
          text-align: center;
          line-height: 70px;
          img {
            width: 100%;
          }
        }
      }
      .right_box {
        flex: 1;
        // width: 100%;
        padding: 10px 20px 0;
        .right_top {
          height: 70%;
          width: 100%;
          border-bottom: 1px dashed #aaa;
          p {
            margin-bottom: 10px;
          }
          .btn {
            margin-left: 10px;
            color: rgb(28, 78, 243);
            cursor: pointer;
          }
        }
        .right_bottom {
          height: 30%;
          display: flex;
          align-items: center;
          span {
            cursor: pointer;
          }
        }
      }
    }
    .off_goods_item {
      position: relative;
      overflow: hidden;
      &::after {
        content: "已下架";
        display: block;
        width: 150px;
        height: 50px;
        background-color: #ddd;
        position: absolute;
        top: 10px;
        right: -40px;
        transform: rotate(45deg);
        text-align: center;
        line-height: 50px;
        font-size: 20px;
      }
    }
  }
  .pagination-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    // background-color: pink;
  }
}
</style>
