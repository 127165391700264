import request from './request'

// 商品分类列表
export function getGoodsTypeListApi (data) {
  return request({
    url: '/client/goods/typeListForGroup',
    method: 'POST',
    data
  })
}

export function openGoodsApi (data) {
  return request({
    url: '/client/goods/goodsOpen',
    method: 'POST',
    data
  })
}

export function getGoodsForTypeApi (data) {
  return request({
    url: '/client/goods/goodsListForType',
    method: 'POST',
    data
  })
}

export function getMyGoodsTypeListApi (data) {
  return request({
    url: '/client/goods/typeListForMy',
    method: 'POST',
    data
  })
}

export function getMyGoodsListApi (data) {
  return request({
    url: '/client/goods/goodsListForMy',
    method: 'POST',
    data
  })
}
