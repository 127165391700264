export const paginationMixins = {
  data () {
    return {
      pageSize: 10, // 每页条数
      pageNum: 1, // 当前页码
      pageTotal: 0, // 页数总数
      pageSizes: [10, 30, 50, 100] // 页码数组
    }
  },
  filters: {
    getTime (value) {
      if (!value) return ''
      return new Date(+new Date(new Date(value).toJSON()) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    }
  },
  methods: {
    // 分页 每页条数切换
    handleSizeChange (callbcak, val) {
      this.pageNum = 1
      this.pageSize = val
      callbcak()
    },
    // 分页 页码值切换
    handleCurrentChange (callbcak, val) {
      this.pageNum = val
      callbcak()
    },
    // 搜索重置
    handleResetSearch (callbcak) {
      this.pageNum = 1
      this.tableSearchData = this.$options.data().tableSearchData
      callbcak()
    },
    // 搜索
    handleTableSearch (callbcak) {
      this.pageNum = 1
      callbcak()
    }
  }
}
